

document.addEventListener('DOMContentLoaded', function () {



    const promoSliderImg = new Swiper(".promo-slider-img", {
        loop: true,
        grabCursor: true,
        spaceBetween: 115,
        slidesPerView: 'auto',
        freeMode: true,
        watchSlidesProgress: true,
        breakpoints: {
            1120: {
                spaceBetween: 115,
            },
            300: {
                spaceBetween: 20,
            },
        },
    });

    const promoSliderText = new Swiper(".promo-slider-text", {
        slidesPerView: 1,
        autoHeight: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });

    const partnersSwiper = new Swiper(".partners-swiper", {
        slidesPerView: 4,
        spaceBetween: 30,
        speed: 800,
        pagination: {
            el: ".partners .swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".partners .swiper-button-next",
            prevEl: ".partners .swiper-button-prev",
        },
        breakpoints: {
            760: {
                slidesPerView: 4,
            },
            560: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            300: {
                slidesPerView: 1.8,
                spaceBetween: 10,
            },
        },
    });

    function slideChange(parent) {
        let parentNode = document.querySelector(parent);
        let bullet = parentNode.querySelectorAll('.partners .swiper-pagination-bullet');
        let bulletAllNum = bullet.length;
        let bulletIndex;


        bullet.forEach((item, i) => {
            bulletIndex = i + 1;
            item.innerHTML = `${bulletIndex}`;
        });
    }

    if (document.querySelector('.partners')) {
        slideChange('.partners');
    }


    const newsSwiper = new Swiper(".news-swiper", {
        slidesPerView: 3,
        spaceBetween: 30,
        speed: 800,
        navigation: {
            nextEl: ".news .swiper-button-next",
            prevEl: ".news .swiper-button-prev",
        },
        breakpoints: {
            1250: {
                slidesPerView: 3,
            },
            760: {
                slidesPerView: 2,
            },
            300: {
                slidesPerView: 1.3,
                spaceBetween: 10,
            },
        },
    });

});