document.addEventListener('DOMContentLoaded', function () {

    $('.services-item__btn').on('click', function () {
        $(this).toggleClass('active');
        $(this).parent().find('.services-item__list').slideToggle(300);
    });

    $('.directions-item').on('click', function () {

        let thisImg = $(this).find('img').attr('src');
        $('.directions__img img').attr('src', `${thisImg}`);

        $(this).addClass('active').siblings().removeClass('active');

    });


    $(".phone-input").mask("+735 (99) 999-99-99");

    $('.burger').on('click', function () {
        $('.burger, .nav, .header').toggleClass('active');
        $('body').toggleClass('scroll-hide');
    });


    const slider1 = document.getElementById('slider1');
    const slider2 = document.getElementById('slider2');

    const formatForSlider = {
        from: function (formattedValue) {
            return Number(formattedValue);
        },
        to: function (numericValue) {
            return Math.round(numericValue);
        }
    };

    noUiSlider.create(slider1, {
        start: 6,
        connect: 'lower',
        step: 6,
        format: formatForSlider,
        range: {
            'min': 0,
            'max': 28
        }
    });

    noUiSlider.create(slider2, {
        start: 50,
        connect: 'lower',
        step: 10,
        format: formatForSlider,
        range: {
            'min': 0,
            'max': 50
        }
    });

    const slider1Val = document.getElementById('slider1-val');
    const slider1Val2 = document.getElementById('slider2-val');

    slider1.noUiSlider.on('update', function (values, handle) {
        slider1Val.innerHTML = values[handle] + ' мес';
    });
    slider2.noUiSlider.on('update', function (values, handle) {
        slider1Val2.innerHTML = values[handle] + ' %';
    });

});